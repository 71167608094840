import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';
import * as v from 'valibot';

import { TwtOrderType } from '~/tenants/twt/model/enums';

export default createPerformableConfig(
  '3d',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      floors: z.coerce.number().min(1, { message: 'The number of floors must be greater than 0.' }),
      branded_url: z.string().url().optional(),
      unbranded_url: z.string().url().optional(),
      detached: z.string().array().optional(),
    }),
  },
  {
    name: '3D Walkthrough',
    thumbnail(metadata: Record<string, unknown>) {
      if ('type' in metadata && metadata.type === TwtOrderType.COMMERCIAL) {
        return 'tenant/twt/matterport/commercial.jpg';
      }

      return 'tenant/twt/matterport/1.jpg';
    },
    color: '#593370',
    short: 'Give your audience a true sense of presence, powered by Matterport.',
    tenant: TenantId.TWIST_TOURS,
    bullets: [
      '3D top view',
      '3D dollhouse view',
      'Guided walkthrough',
      'Dimensionally accurate schematic 2D floor plans',
      '3D space planner',
      '2D floor plan tour with photo hotspots',
    ],
    submit: v.object({
      branded_url: v.pipe(v.string('Please provide a Branded URL.'), v.url('The branded url is not valid.')),
      unbranded_url: v.pipe(v.string('Please provide a Unbranded URL.'), v.url('The unbranded url is not valid.')),
    }),
    images: [],
  },
);
