import { TenantPackageConfig } from '~/tenants/common/registry';
import TwtPhotoConfig, { TwtPhotoType } from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import TwtAerialConfig, { TwtAerialType } from '~/tenants/twt/performable/aerial/TwtAerialConfig';
import TwtVideoConfig from '~/tenants/twt/performable/video/TwtVideoConfig';

const TwtJonnyConfig = {
  id: 'jonny',
  name: 'The Jonny Wells Package',
  description: '',
  configurable: false,
  performables: [
    {
      config: TwtPhotoConfig,
      metadata: { twilight: true, type: TwtPhotoType.INTERIOR_EXTERIOR },
    },
    {
      config: TwtAerialConfig,
      metadata: { type: TwtAerialType.PHOTO_VIDEO },
    },
    {
      config: TwtVideoConfig,
      metadata: { raw: true },
    },
  ],
  eligible: (context) =>
    (context as TwtOrderContext).buyer.metadata.package_jonny === true && (context.metadata.sqft ?? 0) <= 5000,
} satisfies TenantPackageConfig;

export default TwtJonnyConfig;
